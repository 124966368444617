import { useEffect, useState } from "react";
import MainLayout from "./../../Layout/MainLayout";
import "./style.css";
import axios from "axios";
import BASEURL from "../../Config/global";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from "react-redux";
import AudioStrip from "../../Components/AudioStrip";
import CustomAudioCard from "../../Components/CustomAudioCard";
import { userLogout } from "../../Store/Slices/APIs";

const Stories = () => {
  const dispatch = useDispatch();
  const [categorizedSoundsData, setCategorizedSoundsData] = useState([]);


  useEffect(() => {
    dispatch(userLogout())
  }, [])

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${BASEURL}/api/stories/allstoriescategories`
        );
        if (!response.data.error) {
          const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence })
          setCategorizedSoundsData(sortedData);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <MainLayout title="Stories">
        <div className="row mb-3">
          <div className="col-12">
            <Accordion
              defaultActiveKey={["0"]}
              alwaysOpen
              className="customAccordion audio-accordian"
            >
              {categorizedSoundsData && categorizedSoundsData?.map((categorizedData, index) => {
                let sortedChildrens = categorizedData.children_category_data
                if (sortedChildrens?.length > 0) {
                  sortedChildrens = sortedChildrens.sort(function (a, b) { return a.sequence - b.sequence })
                }
                return (
                  <Accordion.Item eventKey={index == 0 ? '0' : Math.random()} key={Math.random()}>
                    <Accordion.Header
                      style={{
                        backgroundImage: `url(${categorizedData?.banner})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundColor: '#1c3664',
                      }}
                    >
                      {categorizedData?.name}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className={index == 0 ? 'audio-boxes-wrapper' : 'audios-rows audioStrips stripedRows'}>
                        {categorizedData?.category_data?.length && categorizedData?.category_data?.map((item, idx) => (
                          index == 0 ? <CustomAudioCard key={index} item={item} /> : <AudioStrip key={index} item={item} />
                        ))}
                      </div>
                      <div className="sub-category-accordian">
                        {sortedChildrens?.length && sortedChildrens?.map((categorizedDataiii, index2) => (
                          <Accordion.Item eventKey={Math.random()} key={Math.random()}>
                            <Accordion.Header
                              className="sub-category-header"
                              style={{
                                backgroundImage: `url(${categorizedDataiii?.banner})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundColor: '#1c3664',
                              }}
                            >
                              {categorizedDataiii?.name}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="audios-rows audioStrips stripedRows">
                                {categorizedDataiii?.category_data?.length && categorizedDataiii?.category_data?.map((item, idx) => (
                                  <AudioStrip key={index} item={item} />
                                ))}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Stories;
