const ListItem = ({ data, icon }) => {
    return (
        <ul className="listing-with-icon">
            {data.map((item, index) => (
                <li className=" " key={index}>
                    <img src={icon} className="tick-style" />
                    <span className="trial-list-item-text text-nowrap">{item}</span>
                </li>
            ))}
        </ul>
    )
}


export default ListItem