import { useEffect, useState } from "react";
import { Daniblifting1280, Daniblifting1366, Daniblifting1920, DanibliftingMob, DanibliftingTablet, JSterling1280, JSterling1366, JSterling1920, JSterlingMob, JSterlingTablet, MHST1280, MHST1366, MHST1920, MHSTMob, MHSTTablet, Pedim1280, Pedim1366, Pedim1920, PedimMob, PedimTablet, QCM1280, QCM1366, QCM1920, QCMMob, QCMTablet, Scofa1280, Scofa1366, Scofa1920, ScofaMob, ScofaRSC1280, ScofaRSC1366, ScofaRSC1920, ScofaRSCMob, ScofaRSCTablet, ScofaTablet, SleepCare1280, SleepCare1366, SleepCare1920, SleepCareMob, SleepCareTablet } from "../../Assets/images";
import Promo from "./Promo";
import CorporatePromo from "./CorporatePromo";
import Common from "../../Util/common";
import "./style.css";

const PromoPush = ({ handleContinueButton }) => {

    const [openedPageData, setOpenedPageData] = useState({});
    const [isCorporatePage, setIsCorporatePage] = useState(false);
    const [loading, setLoading] = useState(true); // New loading state

    useEffect(() => {
        const path = window.location.pathname?.toLowerCase().split("/")[1];
        const routes = Common.LANDING_PAGES;
        if (path != "promoint" && path != "promo" && routes.includes(path)) {
            document.querySelector("body")?.classList?.add("overflow-hidden");
            setOpenedPageData(data[path]);
            setIsCorporatePage(true); 
        }
        setLoading(false); // Mark as loaded after checking conditions
    }, [])

    if (loading) return null; // Prevent rendering until useEffect is executed

    return (
        <>
            {
                isCorporatePage ? (
                    <CorporatePromo data={openedPageData} handleContinue={() => handleContinueButton()} />
                ) : (
                    <Promo data={openedPageData} handleContinue={() => handleContinueButton()} />
                )
            }
        </>
    );
};

export default PromoPush;


const data = {
    "pedim": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state <br class='d-block d-lg-none' />of mind to relax and <br class='d-none d-lg-block' /> help you get a <br class='d-block d-lg-none' /> good night's rest.",
        "text4": "We are here to Help!",
        "text5": "FREE 6-Month Trial for Pedim Patients!",
        "text6": "Free 6 Month Trial available for Pedim patients",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "list": [
            "Sounds & Music",
            "Relaxing Meditations",
            "Guided Adult & Children's Stories",
            "Household accounts included<span class='d-none d-lg-inline-block'>–</span><br class='d-lg-none' />invite everyone in your home!"
        ],
        // "list_by_company": [
        //     "No credit card required for trial",
        //     "Lock in savings after 6 month trial <span class='d-none d-lg-inline-block'>–</span> <br class='d-lg-none' /> Get $20 OFF the regular price",
        //     "Just $39.99/year (Regularly $59.99)"
        // ],
        "img_1920": Pedim1920,
        "img_1366": Pedim1366,
        "img_1280": Pedim1280,
        "img_tablet": PedimTablet,
        "img_mob": PedimMob,
        "bg": Pedim1920,
        "page_name": Common.PEDIM,
        "alignment_out": true
    },
    "pedim-ext": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state <br class='d-block d-lg-none' />of mind to relax and <br class='d-none d-lg-block' /> help you get a <br class='d-block d-lg-none' /> good night's rest.",
        "text4": "We are here to Help!",
        "text5": "FREE 6-Month Trial for Pedim Patients!",
        "text6": "Free 6 Month Trial available for Pedim patients",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "list": [
            "Sounds & Music",
            "Relaxing Meditations",
            "Guided Adult & Children's Stories",
            "Household accounts included<span class='d-none d-lg-inline-block'>-</span><br class='d-lg-none' />invite everyone in your home!"
        ],
        "list_by_company": [
            "Lock in savings after 6 month trial",
            "Get $20 OFF the regular price",
            "Just $39.99/year (Regularly $59.99)"
        ],
        "img_1920": Pedim1920,
        "img_1366": Pedim1366,
        "img_1280": Pedim1280,
        "img_tablet": PedimTablet,
        "img_mob": PedimMob,
        "bg": Pedim1920,
        "page_name": Common.PEDIM,
        "alignment_out": true
    },
    "qcm": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state <br class='d-block d-lg-none' />of mind to relax and <br class='d-none d-lg-block' /> help you get a <br class='d-block d-lg-none' /> good night's rest.",
        "text4": "We are here to Help!",
        "text5": "FREE 6-Month Trial for <br class='d-lg-none' /> Quick Care Med patients!",
        "text6": "Free 6 Month Trial available for Quick Care Med patients",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "list": [
            "Sounds & Music",
            "Relaxing Meditations",
            "Guided Adult & Children's Stories",    
            "Household accounts included<span class='d-none d-lg-inline-block'>–</span><br class='d-lg-none' />invite everyone in your home!"
        ],
        // "list_by_company": [
        //     "No credit card required for trial",
        //     "Lock in savings after 6 month trial <span class='d-none d-lg-inline-block'>–</span> <br class='d-lg-none' /> Get $20 OFF the regular price",
        //     "Just $39.99/year (Regularly $59.99)"
        // ],
        "img_1920": QCM1920,
        "img_1366": QCM1366,
        "img_1280": QCM1280,
        "img_tablet": QCMTablet,
        "img_mob": QCMMob,
        "bg": QCM1920,
        "page_name": Common.QCM,
        "alignment_out": true
    },
    "qcm-ext": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state <br class='d-block d-lg-none' />of mind to relax and <br class='d-none d-lg-block' /> help you get a <br class='d-block d-lg-none' /> good night's rest.",
        "text4": "We are here to Help!",
        "text5": "FREE 6-Month Trial for <br class='d-lg-none' /> Quick Care Med patients!",
        "text6": "Free 6 Month Trial available for Quick Care Med patients",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "list": [
            "Sounds & Music",
            "Relaxing Meditations",
            "Guided Adult & Children's Stories",
            "Household accounts included<span class='d-none d-lg-inline-block'>–</span><br class='d-lg-none' />invite everyone in your home!"
        ],
        "list_by_company": [
            "Lock in savings after 6 month trial",
            "Get $20 OFF the regular price",
            "Just $39.99/year (Regularly $59.99)"
        ],
        "img_1920": QCM1920,
        "img_1366": QCM1366,
        "img_1280": QCM1280,
        "img_tablet": QCMTablet,
        "img_mob": QCMMob,
        "bg": QCM1920,
        "page_name": Common.QCM,
        "alignment_out": true
    },
    "daniblifting": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state of mind to relax and <br class='d-none d-lg-block' /> help you get a good night's rest.",
        "text4": "We are here to Help!",
        "text5": "Relax & Enjoy a Peaceful Night's Sleep",
        "text6": "Falling Asleep During The Day?",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children's Stories", "Sleep Medicine Articles & Guides"],
        "img_1920": Daniblifting1920,
        "img_1366": Daniblifting1366,
        "img_1280": Daniblifting1280,
        "img_tablet": DanibliftingTablet,
        "img_mob": DanibliftingMob,
        "bg": Daniblifting1920,
        "page_name": Common.DANIBLIFTING,
        "alignment_out": false
    },
    "jsterling": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state of mind to relax and <br class='d-none d-lg-block' /> help you get a good night's rest.",
        "text4": "We are here to Help!",
        "text5": "Relax & Enjoy a Peaceful Night's Sleep",
        "text6": "Falling Asleep During The Day?",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children's Stories", "Sleep Medicine Articles & Guides"],
        "img_1920": JSterling1920,
        "img_1366": JSterling1366,
        "img_1280": JSterling1280,
        "img_tablet": JSterlingTablet,
        "img_mob": JSterlingMob,
        "bg": JSterling1920,
        "page_name": Common.JSTERLING
    },
    "mhst": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state of mind to relax and <br class='d-none d-lg-block' /> help you get a good night's rest.",
        "text4": "We are here to Help!",
        "text5": "Relax & Enjoy a Peaceful Night's Sleep",
        "text6": "Falling Asleep During The Day?",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children's Stories", "Sleep Medicine Articles & Guides"],
        "img_1920": MHST1920,
        "img_1366": MHST1366,
        "img_1280": MHST1280,
        "img_tablet": MHSTTablet,
        "img_mob": MHSTMob,
        "mobile_img": MHSTMob,
        "bg": MHST1920,
        "page_name": Common.MHST
    },
    "mhst6": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state of mind to relax and <br class='d-none d-lg-block' /> help you get a good night's rest.",
        "text4": "We are here to Help!",
        "text5": "Relax & Enjoy a Peaceful Night's Sleep",
        "text6": "Falling Asleep During The Day?",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children's Stories", "Sleep Medicine Articles & Guides"],
        "img_1920": MHST1920,
        "img_1366": MHST1366,
        "img_1280": MHST1280,
        "img_tablet": MHSTTablet,
        "img_mob": MHSTMob,
        "mobile_img": MHSTMob,
        "bg": MHST1920,
        "logo": MHST1920,
        "page_name": Common.MHST,
        "is_new": false
    },
    "sleep-care": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state of mind to relax and <br class='d-none d-lg-block' /> help you get a good night's rest.",
        "text4": "We are here to Help!",
        "text5": "Relax & Enjoy a Peaceful Night's Sleep",
        "text6": "Falling Asleep During The Day?",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children's Stories", "Sleep Medicine Articles & Guides"],
        "img_1920": SleepCare1920,
        "img_1366": SleepCare1366,
        "img_1280": SleepCare1280,
        "img_tablet": SleepCareTablet,
        "img_mob": SleepCareMob,
        "mobile_img": SleepCareMob,
        "bg": SleepCare1920,
        "page_name": Common.SLEEPCARE
    },
    "free6": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state of mind to relax and <br class='d-none d-lg-block' /> help you get a good night's rest.",
        "text4": "We are here to Help!",
        "text5": "Relax & Enjoy a Peaceful Night's Sleep",
        "text6": "Falling Asleep During The Day?",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children's Stories", "Sleep Medicine Articles & Guides"],
        "img_1920": Scofa1920,
        "img_1366": Scofa1366,
        "img_1280": Scofa1280,
        "img_tablet": ScofaTablet,
        "img_mob": ScofaMob,
        "mobile_img": ScofaMob,
        "bg": Scofa1920,
        "logo": Scofa1920,
        "page_name": Common.QCM,
        "is_new": false
    },
    "scofa-1gdxmk": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state <br class='d-block d-lg-none' />of mind to relax and <br class='d-none d-lg-block' /> help you get a <br class='d-block d-lg-none' /> good night's rest.",
        "text4": "We are here to Help!",
        "text5": "FREE 1-Month Trial",
        "text6": "Free 1 Month Trial available",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "text8": "This offer is limited to select active promo codes.",
        "list": [
            "Sounds & Music",
            "Relaxing Meditations",
            "Guided Adult & Children's Stories",
            "Household accounts included<span class='d-none d-lg-inline-block'>-</span><br class='d-lg-none' />invite everyone in your home!"
        ],
        "list_by_company": [
            "Lock in savings after 1 month trial",
            "Get $20 OFF the regular price",
            "Just $39.99/year (Regularly $59.99)"
        ],
        "img_1920": ScofaRSC1920,
        "img_1366": ScofaRSC1366,
        "img_1280": ScofaRSC1280,
        "img_tablet": ScofaRSCTablet,
        "img_mob": ScofaRSCMob,
        "bg": ScofaRSC1920,
        "page_name": Common.PEDIM,
        "alignment_out": true
    },
    "scofa-3m8qmx": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state <br class='d-block d-lg-none' />of mind to relax and <br class='d-none d-lg-block' /> help you get a <br class='d-block d-lg-none' /> good night's rest.",
        "text4": "We are here to Help!",
        "text5": "FREE 3-Month Trial",
        "text6": "Free 3 Month Trial available",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "text8": "This offer is limited to select active promo codes.",
        "list": [
            "Sounds & Music",
            "Relaxing Meditations",
            "Guided Adult & Children's Stories",
            "Household accounts included<span class='d-none d-lg-inline-block'>-</span><br class='d-lg-none' />invite everyone in your home!"
        ],
        "list_by_company": [
            "Lock in savings after 3 month trial",
            "Get $20 OFF the regular price",
            "Just $39.99/year (Regularly $59.99)"
        ],
        "img_1920": ScofaRSC1920,
        "img_1366": ScofaRSC1366,
        "img_1280": ScofaRSC1280,
        "img_tablet": ScofaRSCTablet,
        "img_mob": ScofaRSCMob,
        "bg": ScofaRSC1920,
        "page_name": Common.PEDIM,
        "alignment_out": true
    },
    "scofa-6kdxmv": {
        "text1": "Can’t Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state <br class='d-block d-lg-none' />of mind to relax and <br class='d-none d-lg-block' /> help you get a <br class='d-block d-lg-none' /> good night's rest.",
        "text4": "We are here to Help!",
        "text5": "FREE 6-Month Trial",
        "text6": "Free 6 Month Trial available",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "text8": "This offer is limited to select active promo codes.",
        "list": [
            "Sounds & Music",
            "Relaxing Meditations",
            "Guided Adult & Children's Stories",
            "Household accounts included<span class='d-none d-lg-inline-block'>-</span><br class='d-lg-none' />invite everyone in your home!"
        ],
        "list_by_company": [
            "Lock in savings after 6 month trial",
            "Get $20 OFF the regular price",
            "Just $39.99/year (Regularly $59.99)"
        ],
        "img_1920": ScofaRSC1920,
        "img_1366": ScofaRSC1366,
        "img_1280": ScofaRSC1280,
        "img_tablet": ScofaRSCTablet,
        "img_mob": ScofaRSCMob,
        "bg": ScofaRSC1920,
        "page_name": Common.PEDIM,
        "alignment_out": true
    },
    "scofa-50qypz": {
        "text1": "Can't Sleep? Need to Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Our content will put you in the right state <br class='d-block d-lg-none' />of mind to relax and <br class='d-none d-lg-block' /> help you get a <br class='d-block d-lg-none' /> good night's rest.",
        "text4": "We are here to Help!",
        "text5": "50% Off Annual Plan – Limited Time Only!",
        "text6": "Free 6 Month Trial available for Pedim patients",
        "text7": "<span class='d-none'>Give it a try.&nbsp;</span>Get it today and relax tonight!",
        "text8": "This offer is limited to select active promo codes.",
        "list": [
            "Sounds & Music",
            "Relaxing Meditations",
            "Guided Adult & Children's Stories",
            "Household accounts included<span class='d-none d-lg-inline-block'>-</span><br class='d-lg-none' />invite everyone in your home!"
        ],
        "list_by_company": [
            "Yearly plan: Now $29.99 (Reg. $59.99)",
            "Unlimited access to all content",
            "Invite your entire household"
        ],
        "img_1920": ScofaRSC1920,
        "img_1366": Pedim1366,
        "img_1280": Pedim1280,
        "img_tablet": PedimTablet,
        "img_mob": PedimMob,
        "bg": Pedim1920,
        "page_name": Common.PEDIM,
        "alignment_out": true
    },
}