import React, { useState, useEffect } from 'react'
import { Tick } from "../../Assets/svg";

const CorporatePromo = ({ data, handleContinue }) => {

    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        if (window.innerWidth <= 991) {
            document.body.classList.remove("overflow-hidden");
        }
        const handleResize = () => {
            setHeight(window.innerHeight)
            if (window.innerWidth <= 991) {
                document.body.classList.remove("overflow-hidden");
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div className={`fullPageBg promotion-page-wrapper ${data?.page_name}`} style={{
                // backgroundImage: `url(${data?.bg})`
            }}>
                <div className="row g-0 align-items-center" style={{
                    // height: "100vh"
                }}>
                    {/* Plan 2 if bg is not working */}
                    <div className="col-lg-6 d-none d-lg-block promo-left-column">

                        {
                            data?.is_new ? (
                                <img src={data?.logo} alt={data?.logo} className={`img-fluid advertise-logo ${data?.logo}-img`} />
                            ) : (window.innerWidth < 1100 || (window.innerHeight > 900 && window.innerWidth <= 1366)) ? (
                                <img src={data?.img_tablet} alt={data?.img_tablet} className={`img-fluid advertise-img ${data?.page_name}-img`} />
                            ) : (
                                <>
                                    <img src={data?.img_tablet} alt={data?.img_tablet} className={`d-none d-md-block d-lg-none img-fluid advertise-img ${data?.page_name}-img`} />
                                    <img src={data?.img_1280} alt={data?.img_1280} className={`d-none d-lg-block d-xl-none img-fluid advertise-img ${data?.page_name}-img`} />
                                    <img src={data?.img_1366} alt={data?.img_1366} className={`d-none d-xl-block d-xxl-none img-fluid advertise-img ${data?.page_name}-img`} />
                                    <img src={data?.img_1920} alt={data?.img_1920} className={`d-none d-xxl-block img-fluid advertise-img ${data?.page_name}-img`} />
                                </>
                            )
                        }
                    </div>
                    <div className="col-12 col-lg-6 promo-right-column mobile-height-add">
                        <div className="content-wrapper" style={{
                            maxHeight: `${height}px`,
                            overflow: 'auto'
                        }}>

                            {
                                data?.is_new ? (
                                    <img src={data?.logo} alt={data?.logo} className={`img-fluid advertise-mob-logo d-lg-none`} />
                                ) : (
                                    <img src={data?.img_mob} alt={data?.img_mob} className="mobile-img img-fluid w-100 d-lg-none" />
                                )
                            }
                            <div className="content-wrapper-inner py-2">
                                <h2 className="title sub-title-mob mb-1 mt-4 mt-lg-0">{data?.text1}</h2>
                                <h4 className="title mb-3">{data?.text2}</h4>
                                {/* <h4 className="sub-title-h4 mb-1">{data?.text2}</h4> */}
                                <h4 className="sub-title-h4 mb-3 mb-md-4" dangerouslySetInnerHTML={{ __html: data?.text3 }}></h4>
                                {/* <h2 className="sub-title mb-1">{data?.text4}</h2> */}
                                <ListItem icon={Tick} data={data?.list || []} />
                                <h2 className="sub-title mt-4 mt-md-4 mb-2 mb-sm-1" dangerouslySetInnerHTML={{ __html: data?.text5 }}></h2>
                                <ListItem icon={Tick} data={data?.list_by_company || []} />
                                <h4 className={`sub-title text-lg-start ${data?.alignment_out ? `text-center ${data?.list_by_company ? 'mt-4' : 'mt-1'}` : 'text-start'}`} dangerouslySetInnerHTML={{ __html: data?.text7 }}></h4>
                                <div className='mt-4 pt-md-2 text-center text-lg-start'>
                                    <button className="customButton continue-btn" onClick={() => handleContinue()}>Continue</button>
                                </div>
                                {data?.text8 && (
                                    <>
                                        <div style={{ height: '80px' }}></div>
                                        <h6 className="sub-title-h6 text-center text-lg-start" dangerouslySetInnerHTML={{ __html: data?.text8 }}></h6>
                                    </>
                                )}
                                <div className='promo-spacer-200'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
const ListItem = ({ data, icon }) => {
    return (
        <ul className="listing-with-icon">
            {data?.map((item, index) => (
                <li className=" " key={index}>
                    <img src={icon} className="tick-style" />
                    <span dangerouslySetInnerHTML={{ __html: item }}></span>
                </li>
            ))}
        </ul>
    )
}


export default CorporatePromo