import Common from "./common"

export const hideUrlForCountry = function (country) {
    switch (country?.toLowerCase()) {
        case 'us':
            console.log("country", country)
            return true
        case 'usa':
            return true
        case 'united states of america':
            return true
        case 'united states':
            return true
        case 'america':
            return true
        case 'u.s.a':
            return true
        default:
            return false
    }
}

export const checkIsCouponUser = () => {
    const paths = Common.LANDING_PAGES;
    let routePath = window.location.pathname?.toLowerCase().split("/")[1]
    let isCouponUser = paths.find(f => f.toLocaleLowerCase() === routePath.toLocaleLowerCase())
    return isCouponUser
}