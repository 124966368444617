// Enum-like object

const LandingPages = [
    'promo',
    'promoint',
    'pedim',
    'pedim-ext',
    'qcm',
    'qcm-ext',
    'jsterling',
    'daniblifting',
    'mhst',
    'sleep-care',
    'free6',
    'mhst6',
    'scofa-1gdxmk',
    'scofa-3m8qmx',
    'scofa-6kdxmv',
    'scofa-50qypz'
]

const Common = Object.freeze({
    FEATUREDMIXES: 'Featured Mixes',
    RELAXATION: 'relaxation',
    RELAXANDREST: 'Relax & Rest',
    RELAXREST: 'relax-rest',
    MIXURE: "mixture",
    JSTERLING: "jsterling",
    PEDIM: "pedim",
    QCM: "qcm",
    DANIBLIFTING: "daniblifting",
    MHST: "mhst",
    SLEEPCARE: "sleep-care",
    LANDING_PAGES: LandingPages
});

export default Common;