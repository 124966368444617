import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import MainLayout from "./../../Layout/MainLayout";

import "./style.css";
import { getAccessToken } from "../../Util/authHeader";
import axios from "axios";
import BASEURL from "../../Config/global";
import { iphoneMockup, logo, Membership } from "../../Assets/images";
import { Tick } from "../../Assets/svg";




const Upgrade = () => {


    return (
        <>
            <div className="fullPageBg d-flex flex-column align-items-center justify-content-center h-100 py-3" style={{ minHeight: '100vh' }}>
                <div className="free-trial logo d-flex align-items-center justify-content-center my-1">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="container-lg mb-2 mb-sm-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="caption-free">Can’t Sleep? Can’t Relax?</h1>
                            <h6 className="subcaption-free-trial">Waking up in the middle of the Night? <br/>
                            Having Rumanating Thoughts?<br className="d-md-none" /> Falling Asleep During The Day?</h6>
                        </div>
                    </div>
                </div>
                <div className='container-lg membership-container '>
                    <div className="row">
                        <div className="col-md-6  ml-auto ml-md-0 membership-container-free">
                            <div className="my-md-2">
                                <img src={iphoneMockup} alt="iphone-mock" className="img-fluid membership-image" />
                            </div>
                        </div>
                        <div className="col-md-6  col-xl-6  mt-5">
                            <div className="w-100 mx-auto ms-md-start">
                                <div className="row  mb-4 mt-3 mt-md-0 ms-lg-4">
                                    <div className="col-11 col-md-12 col-xl-12 mx-auto">
                                        <p className="text-center text-md-start align-list-box-freetrial ms-lg-0 ms-md-4 bullet-point mb-4">We are here to Help!</p>
                                        <div className="ps-md-0 px-4 ms-4 ms-lg-0 mb-2">
                                        <ListItem icon={Tick} data={["Calm Sounds & Music", "Soothing Meditations", "Adult & Children's Stories", "Sleep Articles"]} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-md-3 px-lg-2 mx-1 "  >
                                    <div className="col-12 col-xl-12">
                                        <div className="text-start">
                                            <p className="text-center text-md-start free-trial-bottom-heading mb-0">Relax & Enjoy a Peaceful Night's Sleep</p>
                                            <p className="text-center text-md-start free-trial-bottom-heading-sub">FREE Sub-accounts for everyone <br className="d-md-none"/> in your household </p>
                                            <div className="d-flex justify-content-center justify-content-md-start pt-2 pt-ms-4 mt-4">
                                                <Link to={"/signup"} className="btn rounded-5 px-4 py-2 stop-color " style={{ backgroundColor: '#EFB729', fontSize: '12px' }}>Continue</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    );
};
const ListItem = ({ data, icon }) => {
    return (
        <ul className="listing-with-icon">
            {data.map((item, index) => (
                <li className=" " key={index}>
                    <img src={icon} className="tick-style" />
                    <span className="bullet-point-items">{item}</span>
                </li>
            ))}
        </ul>
    )
}
export default Upgrade;
